import React from "react"

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <footer className="bg-dark py-5 text-white">
        <div className="container ">
          <div className="small text-center text-white">
            Attorney Advertising. This website is designed for general
            information only.
            <br />
            The information presented at this site should not be construed to be
            formal legal advice nor the formation of a lawyer/client
            relationship.
            <br />
            Prior results do not guarantee a similar outcome.
            <br />
            Copyright &copy; 2020-2024 Anthony A. Nozzolillo, Esq.
            <br />
            All Rights Reserved.
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
